import { template as template_01a1af5be8dc4deea1611e3b0c4677ec } from "@ember/template-compiler";
const FKControlMenuContainer = template_01a1af5be8dc4deea1611e3b0c4677ec(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
